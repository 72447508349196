import React, { useState, useEffect } from 'react';
import { Button, Container, Card, TextField, Typography, Box, Grid, Autocomplete, FormControl } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function SelectBaseImage(props) {
    const [baseImages, setBaseImages] = useState([]);
    const [selected, setSelected] = useState();
    const [textBarContent, setTextBarContent] = useState('');
    const [imageName, setImageName] = useState('');

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    const fetchRepoPck = (feedBundle) => {
        fetch(process.env.REACT_APP_URL_REPO +
            '?feedName=' + feedBundle +
            '&packageNameQuery=', {
            credentials: 'include',
            method: "GET"
        })
            .then((resp) => resp.json())
            .then((data) => {
                let resultString = '';
                data.forEach((element) => {
                    resultString += `${element.name}, `;
                });
                resultString = resultString.slice(0, -2); // Rimuove la virgola finale e lo spazio
                setTextBarContent(resultString);
                props.setRepoPkgBundleSelString(resultString);
            })
            .catch((error) => {
                console.log(error, "Errore!");
            });
    }

    useEffect(() => {
        function fetchDefaultData() {
            setTextBarContent('');
            const tempArray = [];
            const payload = {
                datacenter: props.statusForm.datacenter,
                os: props.statusForm.os,
                kindImage: props.statusForm.kindImage
            };

            const formBody = "payload=" + encodeURIComponent(JSON.stringify(payload));

            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_BASE_IMAGES, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => resp.json())
                    .then((data) => {
                        data.forEach((element) => {
                            if (element.imageType === "template") {
                                tempArray.push({
                                    label: `[${element.imageOs}] ${element.imageName} - ${element.imageDes}`,
                                    value: {
                                        idImage: `${element.idImage}`,
                                        imageOs: `${element.imageOs}`,
                                        imageName: `${element.imageName}`,
                                        imageSize: `${element.imageSize}`,
                                        imageSizeCustomizable: `${element.imageSizeCustomizable}`,
                                        feed: `${element.imageFeed}`,
                                        feedBundle: `${element.imageFeedBundle}`,
                                        imageDes: `${element.imageDes}`,
                                        imageType: `${element.imageType}`,
                                        destination: {
                                            id: `${element.destId}`,
                                            des: `${element.destDes}`,
                                            url: `${element.destUrl}`,
                                        }
                                    }
                                });
                            }
                        });
                    })
                    .then(() => {
                        setBaseImages(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!");
                    });
            });
        }

        fetchDefaultData();

        if (props.isBookable) {
            setSelected(null);
        }
    }, [props.isBookable, props.statusForm]);

    function onSelectChange(selectedOption) {
        if (selectedOption) {
            props.setBaseImage(selectedOption.value);
            fetchRepoPck(selectedOption.value.feedBundle);
            setImageName(selectedOption.value.imageName);
            props.setIsBookable(false);
            setSelected(selectedOption);
            props.setMultipleBaseStepCompleted(true);
        }
    }

    return (
        <Container>
            <form>
                <Box mb={3}>
                    <Typography variant="h6">Wizard per la scelta dell'immagine base</Typography>
                </Box>


                <Card sx={{ padding: 3 }}>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={9} >
                            <FormControl fullWidth>
                                <Typography variant="subtitle1">Immagine da personalizzare</Typography>
                                <Autocomplete
                                    value={selected || null}
                                    onChange={(event, newValue) => onSelectChange(newValue)}
                                    options={baseImages}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Selezionare l'immagine base"
                                            variant="outlined"
                                        />
                                    )}
                                    freeSolo
                                />
                            </FormControl>
                            <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                value={props.baseImage}
                                required={true}
                                readOnly
                            />
                            <Box mt={2}>
                                <Typography variant="subtitle1">Nome Immagine</Typography>
                                <TextField
                                    value={imageName}
                                    readOnly
                                    fullWidth
                                />
                            </Box>
                            <Box mt={2} sx={{ minHeight: '200px', height: 'auto', maxHeight: '100%' }}>
                                <Typography variant="subtitle1">Pacchetti</Typography>
                                <TextField
                                    value={textBarContent}
                                    readOnly
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {
                                            minHeight: '100px', // Altezza minima
                                            maxHeight: '100%', // Altezza massima per limitare l'espansione
                                            overflow: 'auto'
                                        }
                                    }}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">1. Sistema operativo</Typography>
                                <Typography variant="body2"><b>{props.statusForm.os}</b></Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">2. Tipo immagine</Typography>
                                <Typography variant="body2"><b>{props.statusForm.kindImage}</b></Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-lg bg-white rounded">
                                <Typography variant="body1">3. Selezione immagine</Typography>
                                <Typography variant="body2"><b>{selected ? selected.value.imageName : null}</b></Typography>
                            </Box>
                            <Button
                                variant="contained"
                                startIcon={<ArrowBackIcon />}
                                onClick={back}
                            >
                                Indietro
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        </Container>
    );
}

export default SelectBaseImage;