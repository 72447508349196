import React, { useRef } from 'react';
import { TextField, InputLabel, Typography, Alert, Card } from '@mui/material';

function LabDescription(props) {
    const targetBadDescriptionAlert = useRef(null);

    return (
        <Card sx={{ padding: 3 }}>
            <InputLabel>Descrizione del Laboratorio Virtuale</InputLabel>
            <Typography variant="body2" color="textSecondary">
                <b>Dato obbligatorio</b>
            </Typography>
            <TextField
                fullWidth
                type="text"
                onChange={props.labDescriptionHandler}
                placeholder="Descrizione del Laboratorio"
                variant="outlined"
                margin="normal"
                inputRef={targetBadDescriptionAlert}
                required
                error={!props.isLabdescriptionSpellChecked}
            />

            {!props.isLabdescriptionSpellChecked &&
                <Alert variant="danger">
                    Attenzione! Il nome inserito contiene caratteri non consentiti. Inserire un nome diverso.
                </Alert>
            }
        </Card>
    );
}

export default LabDescription;