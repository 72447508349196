import React, { useState, useRef, useEffect } from 'react';
import { Typography, Container, Stepper, Step, StepLabel, TextField, Box, IconButton, Card } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SelectAd from './SelectAd';
import SelectRepoPkg from './SelectRepoPkg';
import ReqLabConfirm from './ReqLabConfirm';
import MultipleStepBaseImage from './MultipleStepBaseImage';
import SelectSize from './SelectSize';
import LabName from './LabName';
import LabDescription from './LabDescription';
import AgreementLab from './AgreementLab';
import SelectExpDate from './SelectExpDate';
import Summary from '../reqExamLab/Summary';


function ReqLabPage(props) {
    const initialExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 18));

    const firstRender = useRef(true);
    const [activeStep, setActiveStep] = useState(0);

    const [adSel, setAdSel] = useState();
    const [coperEasy, setCoperEasy] = useState([]);
    const [checkedImportEasy, setImportEasy] = useState(true);
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [repoPkgBundleSelString, setRepoPkgBundleSelString] = useState("");
    const [installedPackages] = useState([]);
    const [labName, setLabName] = useState("");
    const [labDescription, setLabDescription] = useState("");
    const [labNotes, setLabNotes] = useState();
    const [labOtherActivities, setLabOtherActivities] = useState("");
    const [baseImage, setBaseImage] = useState({});
    const [isBookable, setIsBookable] = useState(false);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isPkgListModified, setIsPkgListModified] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [isLabnameChecked, setIsLabnameChecked] = useState(true);
    const [isLabnameSpellChecked, setIsLabnameSpellChecked] = useState(true);
    const [isLabdescriptionSpellChecked, setIsLabdescriptionSpellChecked] = useState(true);
    const [isAdSpellChecked, setIsAdSpellChecked] = useState(true);
    const [expirationMonthDuration, setExpirationMonthDuration] = useState('18');
    const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
    const [isMultipleBaseStepCompleted, setMultipleBaseStepCompleted] = useState(false);
    const [isSelectAdCompleted, setSelectAdCompleted] = useState(false);
    const [isLabNameCompleted, setLabNameCompleted] = useState(false);
    const [isLabDescCompleted, setLabDescCompleted] = useState(false);
    const [isAgreementCompleted, setAgreementCompleted] = useState(false);
    const [stepsData, setStepsData] = useState({});

    useEffect(() => {
        const formValidation = () => {
            var isOkLabName = false;
            var isOkLabDescription = false;
            var isOkAttivitaDidattica = false;

            if (!isLabnameChecked || labName === "" || !labName.match(/^[a-z|A-Z|0-9|-]*$/)) {
                isOkLabName = false
            } else {
                isOkLabName = true
                setIsLabnameSpellChecked(true);
            }

            if (labName.length > 0 && isOkLabName === false) {
                setIsLabnameSpellChecked(false);
            } else {
                setIsLabnameSpellChecked(true);
            }

            if (labDescription === "" || !labDescription.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkLabDescription = false
            } else {
                isOkLabDescription = true
                setIsLabdescriptionSpellChecked(true);

            }

            if (labDescription.length > 0 && isOkLabDescription === false) {
                setIsLabdescriptionSpellChecked(false);
            } else {
                setIsLabdescriptionSpellChecked(true);
            }

            if (labOtherActivities === "" || !labOtherActivities.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkAttivitaDidattica = false;
            } else {
                isOkAttivitaDidattica = true;
                setIsAdSpellChecked(true);
            }

            if (labOtherActivities.length > 0 && isOkAttivitaDidattica === false) {
                setIsAdSpellChecked(false);
            } else {
                setIsAdSpellChecked(true);
            }

            if (isOkAttivitaDidattica === false && adSel !== undefined) {
                isOkAttivitaDidattica = true;
            }

            return (isOkLabName && isOkLabDescription && isOkAttivitaDidattica);
        }

        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsSubmitble(formValidation());

    }, [labName, labDescription, isLabnameChecked, labOtherActivities, adSel]);


    const labType = "standard";

    const steps = [
        'Immagine base',
        'Pacchetti aggiuntivi',
        'Durata laboratorio',
        'Dimensionamento macchine',
        'Attività didattica',
        'Nome Laboratorio',
        'Descrizonione Laboratorio',
        'Note aggiuntive',
        'Agreement',
        'Conferma'
    ];


    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
    };


    // Funzione per gestire il passaggio alla prossima fase
    const handleNext = () => {
        // get the data of the current step
        let currentStepData;
        switch (activeStep) {
            // selezione immagine base
            case 0:
                currentStepData = (
                    <Typography>
                        Immagine Selezionata: <Typography component="span" fontWeight="bold">{baseImage.imageName} {baseImage.imageDes}</Typography>
                        <br />
                        Pacchetti presenti nel bundle: <Typography component="span" fontWeight="bold">{repoPkgBundleSelString}</Typography>
                    </Typography>
                );
                break;
            // selezione pacchetti
            case 1:
                let repoPkgSelString;
                if (repoPkgSel.length === 0) {
                    repoPkgSelString = "Nessun pacchetto addizionale";
                } else {
                    repoPkgSelString = repoPkgSel.map(item => item.value).join(', ');
                }

                currentStepData = (
                    <Typography>
                        Pacchetti aggiuntivi selezionati: <Typography component="span" fontWeight="bold">{repoPkgSelString}</Typography>
                    </Typography>
                );
                break;
            // selezione data di scadenza
            case 2:
                currentStepData = (
                    <Typography>
                        Data di scadenza: <Typography component="span" fontWeight="bold">{expirationMonthDuration} mesi (data di scadenza: {formatDate(expirationDate)})</Typography>
                    </Typography>
                );
                break;
            // selezione dimensionamento
            case 3:
                currentStepData = (
                    <Typography>
                        Selezione dimensionamento: <Typography component="span" fontWeight="bold">{baseImage.imageSize}</Typography>
                    </Typography>
                );
                break;
            case 4:
                currentStepData = (
                    <Typography>
                        Attività didattica: <Typography component="span" fontWeight="bold">[{adSel.anaAfCod}-{adSel.anaModCod}] {adSel.anaAfDescIta}</Typography>
                    </Typography>
                );
                break;
            case 5:
                currentStepData = (
                    <Typography>
                        Nome laboratorio: <Typography component="span" fontWeight="bold">{labName}</Typography>
                    </Typography>
                );
                break;
            case 6:
                currentStepData = (
                    <Typography>
                        Descrizione laboratorio: <Typography component="span" fontWeight="bold">{labDescription}</Typography>
                    </Typography>
                );
                break;
            case 7:
                currentStepData = (
                    <Typography>
                        Note aggiuntive: <Typography component="span" fontWeight="bold">{labNotes}</Typography>
                    </Typography>
                );
                break;
            case 8:
                currentStepData = (
                    <Typography>
                        Nota sulla mancata persistenza accettata
                    </Typography>
                );
                break;
            default:
                break;
        }

        // update stepsData with the data of the current step
        setStepsData(prevStepsData => ({
            ...prevStepsData,
            [`Step ${activeStep + 1}`]: currentStepData,
        }));


        // increment activeStep
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // Funzione per gestire il ritorno alla fase precedente
    const handleBack = () => {
        switch (activeStep) {
            // 
            case 0:
                break;
            // selezione immagine base
            case 1:
                setMultipleBaseStepCompleted(false);
                break;
            // selezione pacchetti
            case 2:
                break;
            // selezione data di scadenza
            case 3:
                setSelectAdCompleted(false);
                break;
            // selezione dimensionamento
            case 4:
                setSelectAdCompleted(false);
                break;
            // selezione AD
            case 5:
                setSelectAdCompleted(false);
                break;
            // selezione nome laboratorio
            case 6:
                setLabNameCompleted(false);
                break;
            // descrizione laboratorio
            case 7:
                setLabDescCompleted(false);
                break;
            // note aggiuntive
            case 8:
                break;
            case 9:
                break;
            case 10:
                break;

            default:
                break;
        }

        // Cancella i dati dello step corrente
        setStepsData((prevStepsData) => {
            const newStepsData = { ...prevStepsData };
            delete newStepsData[`Step ${activeStep}`];
            return newStepsData;
        });

        // Logica esistente per gestire il passo indietro
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    };


    const handleSelectAdCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setSelectAdCompleted(true);
    };

    const handleLabNameCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setLabNameCompleted(true);
    };

    const handleAgreementCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setAgreementCompleted(true);
    };

    const RenderIconButton = ({ ldisabled, rdisabled }) => {
        return (
            <Box sx={{ width: "100%", marginTop: "20px", marginBottom: "50px" }}>

                <IconButton
                    style={{ float: 'left', fontWeight: 'bold', fontSize: '3rem' }}
                    onClick={handleBack}
                    disabled={ldisabled}
                >
                    <ArrowBackIcon
                        style={{
                            fontSize: '3rem',
                            color: ldisabled ? 'gray' : 'blue'
                        }}
                    />
                </IconButton>
                <IconButton
                    style={{ float: 'right', fontWeight: 'bold', fontSize: '3rem' }}
                    onClick={handleNext}
                    disabled={rdisabled}
                >
                    <ArrowForwardIcon
                        style={{
                            fontSize: '3rem',
                            color: rdisabled ? 'gray' : 'blue'
                        }}
                    />
                </IconButton>
            </Box>
        );
    };

    const usageType = () => {
        return (
            <React.Fragment>
                <form onSubmit={onSubmit}>

                    <Container>
                        <Summary stepsData={stepsData} />
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={true} rdisabled={!isMultipleBaseStepCompleted} />
                                <MultipleStepBaseImage
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                    setIsBookable={setIsBookable}
                                    isBookable={isBookable}
                                    setRepoPkgBundleSelString={setRepoPkgBundleSelString}
                                    setMultipleBaseStepCompleted={setMultipleBaseStepCompleted}
                                />
                                <RenderIconButton ldisabled={true} rdisabled={!isMultipleBaseStepCompleted} />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={false} />
                                <SelectRepoPkg
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setRepoPkgSel={setRepoPkgSel}
                                    setRepoPkgBundleSel={setRepoPkgBundleSel}
                                    installedPackages={installedPackages}
                                    baseImage={baseImage}
                                    setIsPkgListModified={setIsPkgListModified}
                                />
                                <RenderIconButton ldisabled={false} rdisabled={false} />
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={false} />
                                <SelectExpDate
                                    expDate={expirationMonthDuration}
                                    onExpDateChange={handleExpDateChange} />
                            </Box>
                        )}
                        {activeStep === 3 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={false} />
                                <SelectSize
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                />
                            </Box>

                        )}
                        {activeStep === 4 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={!isSelectAdCompleted} />
                                <SelectAd
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setAdSelected={setAdSel}
                                    setCoperEasy={setCoperEasy}
                                    isAdSpellChecked={isAdSpellChecked}
                                    setCoperTeacher={setCoperTeacher}
                                    labOtherActivitiesSelected={setLabOtherActivities}
                                    labName={labName}
                                    onCompletion={handleSelectAdCompletion}
                                    setImportEasy={setImportEasy}
                                    checkedImportEasy={checkedImportEasy}
                                />
                                <RenderIconButton ldisabled={false} rdisabled={!isSelectAdCompleted} />
                            </Box>
                        )}
                        {activeStep === 5 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={!isLabNameCompleted} />
                                <LabName
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    adSel={adSel}
                                    labNameSelected={setLabName}
                                    setIsLabnameChecked={setIsLabnameChecked}
                                    isLabnameSpellChecked={isLabnameSpellChecked}
                                    isSubmitted={isSubmitted}
                                    onCompletion={handleLabNameCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 6 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={!isLabDescCompleted || !isLabdescriptionSpellChecked} />
                                <LabDescription
                                    labDescriptionHandler={labDescriptionHandler}
                                    isLabdescriptionSpellChecked={isLabdescriptionSpellChecked}
                                />
                            </Box>
                        )}

                        {activeStep === 7 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <label style={{ width: "85%", marginTop: "50px" }}> Note aggiuntive </label>
                                <RenderIconButton ldisabled={false} rdisabled={false} />
                                <Card sx={{ padding: 3 }}>
                                    <TextField
                                        multiline
                                        rows={3}
                                        maxLength={2000}
                                        placeholder="Inserire eventuali note aggiuntive"
                                        onChange={labNotesHandler}
                                        fullWidth
                                    />
                                </Card>
                            </Box>
                        )}
                        {activeStep === 8 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={!isAgreementCompleted} />
                                <AgreementLab
                                    onCompletion={handleAgreementCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 9 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <RenderIconButton ldisabled={false} rdisabled={true} />
                                <ReqLabConfirm
                                    isSubmitted={isSubmitted}
                                    isSubmitble={isSubmitble}
                                    statusRequest={statusRequest}
                                    apiAuthGroups={props.apiAuthGroups}
                                    isPkgListModified={isPkgListModified}
                                    buttonDescription={"Istanzia laboratorio"}
                                />
                            </Box>
                        )}
                    </Container>
                </form>
            </React.Fragment>
        );


    }


    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var myTeachers = [];
        var labEvents = [];
        var url;

        url = process.env.REACT_APP_URL_SAVE_REQ_LAB;

        if (coperTeacher.length > 0) {
            myTeachers = coperTeacher;
        } else {
            myTeachers = [{ email: props.apiAuthResponse, partitions: [0] }];
        }

        if (coperEasy.length > 0 && checkedImportEasy) {
            labEvents = coperEasy.map(event => ({
                ...event,
                scheduleType: "easy",
                teacher: { email: props.apiAuthResponse, partition: 0 }
            }));
        } else {
            labEvents = [];
        }


        const payload = (
            {
                idLab: baseImage.idLab,
                email: props.apiAuthResponse,
                //teachers: [{ email: props.apiAuthResponse, partitions: [0] }],
                //teachers: coperTeacher,
                teachers: myTeachers,
                labName: labName,
                expirationDate: expirationDate,
                labDescription: labDescription,
                labType: labType,
                repoPkgBundleSel: repoPkgBundleSel,
                repoPkgSel: repoPkgSel,
                adSel: adSel,
                labOtherActivities: labOtherActivities,
                labNotes: labNotes,
                baseImage: {
                    os: baseImage.imageOs,
                    name: baseImage.imageName,
                    des: baseImage.imageDes,
                    size: baseImage.imageSize,
                    feed: baseImage.feed,
                    feedBundle: baseImage.feedBundle,
                    imageType: baseImage.imageType,
                    destination: baseImage.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //quota: quota,
                    schedulerLab: labEvents,
                },
                students: []
            }
        );

        formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }



    const labDescriptionHandler = (event) => {
        setLabDescription(event.target.value);
        setLabDescCompleted(true);
    }

    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    const handleExpDateChange = (newExpDate) => {
        const currentDate = new Date();
        const monthDurations = {
            '3': 3,
            '6': 6,
            '12': 12,
            '18': 18
        };

        // Calcola la nuova data di scadenza
        const monthsToAdd = monthDurations[newExpDate] || 0;
        const calculatedExpirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));
        const isoExpirationDate = calculatedExpirationDate.toISOString();

        // Aggiorna gli stati
        setExpirationMonthDuration(newExpDate);
        setExpirationDate(isoExpirationDate);

        console.log("DEBUG expirationMonthDuration: " + newExpDate);
        console.log("DEBUG expirationDate: " + isoExpirationDate);
    };

    return (
        <React.Fragment>
            <h2 className="title-page">Richiesta Laboratorio Virtuale</h2>
            <p>Da questa pagina è possibile attivare un Laboratorio Virtuale</p>


            <br />

            {usageType(baseImage)}


        </React.Fragment>
    );

}

export default ReqLabPage;
