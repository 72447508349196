import React, { useEffect, useState } from 'react';
import { FormControl, TextField, Grid, Slider, Typography, Card } from '@mui/material';


function SelectSize(props) {
    const [currValue, setCurrValue] = useState(0);
    const [currHw, setCurrHw] = useState('');
    const [isCustomizable, setIsCustomizable] = useState(false);

    var ret;
    var size = "";

    const rangeHandler = (e, newValue) => {
        switch (newValue) {
            case 0:
                size = "small";
                setCurrHw("2 CPU, 4GB RAM")
                break;
            case 1:
                size = "medium";
                setCurrHw("2 CPU, 6GB RAM")
                break;
            case 2:
                size = "large";
                setCurrHw("4 CPU, 8GB RAM")
                break;
            case 3:
                size = "xlarge";
                setCurrHw("4 CPU, 16GB RAM")
                break;
            default:
                size = "large";
                setCurrHw("4 CPU, 8GB RAM")
                break;
        }
        setCurrValue(newValue);

        var tempBaseImage = props.baseImage;
        tempBaseImage.imageSize = size;
        props.setBaseImage(tempBaseImage);
    }

    useEffect(() => {
        if (props.baseImage) {

            switch (props.baseImage.imageSize) {
                case "small":
                    setCurrValue(0);
                    setCurrHw("2 CPU, 4GB RAM")
                    break;
                case "medium":
                    setCurrValue(1);
                    setCurrHw("2 CPU, 6GB RAM")
                    break;
                case "large":
                    setCurrValue(2);
                    setCurrHw("4 CPU, 8GB RAM")
                    break;
                case "xlarge":
                    setCurrValue(3);
                    setCurrHw("4 CPU, 16GB RAM")
                    break;
                default:
                    setCurrValue(2);
                    setCurrHw("4 CPU, 8GB RAM")
            }

            if (props.baseImage.imageSizeCustomizable === "0")
                setIsCustomizable(false);
            else
                setIsCustomizable(true);
        }

    }, [props.baseImage]);

    if (props.baseImage.imageName) {
        var maxImageSize = 2;

        ret = (
            <Card sx={{ padding: 3 }}>
                <FormControl fullWidth>
                    <Typography variant="h6">Dimensionamento macchine</Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Slider
                                disabled={!isCustomizable}
                                min={0}
                                max={maxImageSize}
                                value={currValue}
                                onChange={rangeHandler}
                                aria-labelledby="formLabRequestSize"
                                valueLabelDisplay="off"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                type="text"
                                value={currHw}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        {!isCustomizable && (
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary">
                                    L'immagine base selezionata ha un dimensionamento predefinito non modificabile.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </FormControl>
                <br />
            </Card>
        );
    } else {
        ret = null;
    }

    return ret;
}

export default SelectSize;