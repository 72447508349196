import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Grid, Alert, Autocomplete, Card } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';


function SelectAd(props) {
  const [dataLib, setDataLib] = useState([]);
  const [searchLib, setSearchLib] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [emailOptions, setEmailOptions] = useState([]);
  const [prevSelectedRowIndex, setPrevSelectedRowIndex] = useState(null);

  const { setAdSelected, setCoperTeacher, setCoperEasy, onCompletion } = props;



  const columnsLib = [
    {
      accessorKey: 'docPostaElettronica',
      header: 'E-mail Docente',
      //size: 200,
    },
    {
      accessorKey: 'anaAfDescIta',
      header: 'Attività Didattica',
      //size: 150,
    },
    {
      accessorKey: "anaAfCod",
      header: "Codice Attività Didattica",
      //size: 100,
    },
    {
      accessorKey: "anaModDescIta",
      header: "Modulo",
    },
    {
      accessorKey: "anaModCod",
      header: "Codice Modulo",
    },
    {
      accessorKey: "easyAdCod",
      header: "Codice Easy",
    },

  ];



  const table =
    useMaterialReactTable({
      columns: columnsLib,
      data: dataLib || [],
      muiTableBodyCellProps: ({ cell }) => ({
        sx: {
          fontWeight: 'normal',
          fontSize: '14px',
        },
      }),
      enableGlobalFilterModes: false,
      enablePagination: true,
      enableBottomToolbar: false,
      paginationDisplayMode: 'pages',
      pageSize: 15,
      enableColumnPinning: false,
      enableRowNumbers: false,
      enableRowVirtualization: true,
      enableRowSelection: true,
      enableColumnResizing: false,
      onRowSelectionChange: setRowSelection,
      enableMultiRowSelection: false,
      enableSelectAll: false,
      state: {
        rowSelection,
        columnVisibility: {
          easyAdCod: false,
          anaModDescIta: false,
        },
        columnOrder: [
          'mrt-row-select',
          'docPostaElettronica',
          'anaAfDescIta',
          'anaAfCod',
          'anaModDescIta',
          'anaModCod',
          'easyAdCod'],
      }
    });


  // Funzione per gestire la ricerca di un docente per email
  // e per aggiornare la tabella con i risultati della ricerca
  // e per resettare la selezione della riga
  const searchLibHandler = (lib) => {
    setSearchLib(lib.target.value);
    setRowSelection({});
  }


  // Carica le opzioni di email da una chiamata REST API
  // al caricamento del componente
  useEffect(() => {
    // Funzione per caricare le opzioni di email da una chiamata REST API
    const fetchEmailOptions = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL_GET_DOCEMAIL_AFS, {
          credentials: 'include',
          method: 'GET',
        });
        const data = await response.json();
        const emailList = data.map(item => item.docPostaElettronica); // Estrai il campo docPostaElettronica
        const uniqueEmailList = [...new Set(emailList)]; // Rimuovi i duplicati
        setEmailOptions(uniqueEmailList);
      } catch (error) {
        console.error('Errore nel caricamento delle opzioni di email:', error);
      }
    };

    fetchEmailOptions();
  }, []);


  // Carica i dati della tabella attività didattiche con i risultati della ricerca
  // di un docente per email
  useEffect(() => {
    var formBody = [];
    var payload = {
      //key: chiave,
      key: "",
      value: searchLib
    }

    function fetchData() {
      setTimeout(async () => {
        formBody.push("payload=" + JSON.stringify(payload));
        await fetch(process.env.REACT_APP_URL_GET_AFS_BY_KEY, {
          credentials: 'include',
          method: "POST",
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
          },
          body: formBody
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            setDataLib(data);
          })
          .catch((error) => {
            console.log(error, "Errore! FetchData REACT_APP_URL_GET_AFS_BY_KEY")
          });
      });
    }

    setDataLib([])

    fetchData();
  }, [searchLib]);


  // Carica i dati dell'attività didattica selezionata
  // quando viene selezionata una riga della tabella
  useEffect(() => {


    // Funzione per caricare gli eventi calendario EasyRoom dell'attività didattica selezionata
    // TODO: da implementare in POST, altrimenti utilizza solo il primo elemento dell'array
    const fetchEasy = (easyAdCod) => {
      const tempEasy = [];
      setTimeout(async () => {
        const easyAdCodParam = easyAdCod[0];
        await fetch(process.env.REACT_APP_URL_EASY_EVENTS + '?easyAdCod=' + easyAdCodParam, {
          credentials: 'include',
          method: "GET"
        })
          .then((resp) => {
            return resp.json()
          })
          .then((data) => {
            data.forEach((element) => {
              tempEasy.push(
                {
                  start: `${element.start}`,
                  end: `${element.end}`,
                });
            });
          })
          .then(() => {
            setCoperEasy(tempEasy);
          })
          .catch((error) => {
            console.log(error, "Errore!")
          });
      });
    }


    const selectedRowIndex = Object.keys(rowSelection).find(key => rowSelection[key]);

    if (selectedRowIndex !== undefined && selectedRowIndex !== prevSelectedRowIndex) {
      const selectedRow = table.getRow(parseInt(selectedRowIndex, 10))?.original;
      if (selectedRow) {
        fetchEasy(selectedRow.easyAdCod);

        setAdSelected({
          anaAfCod: selectedRow.anaAfCod,
          anaAfDescIta: selectedRow.anaAfDescIta,
          anaModCod: selectedRow.anaModCod,
          anaModDescIta: selectedRow.anaModDescIta,
          easyAdCod: selectedRow.easyAdCod,
          docPostaElettronica: selectedRow.docPostaElettronica,
        });
        //setCoperTeacher(selectedRow.docPostaElettronica);

        setCoperTeacher(prevCoperTeacher => [
          ...prevCoperTeacher,
          selectedRow.docPostaElettronica
        ]);

        //setCoperEasy(selectedRow.easyAdCod)
        onCompletion();
        setPrevSelectedRowIndex(selectedRowIndex);
        //console.log("adSelected", JSON.stringify(selectedRow));
      }
    }
  }, [rowSelection, table, onCompletion, setAdSelected, setCoperTeacher, setCoperEasy, prevSelectedRowIndex]);


  return (
    <Card sx={{ padding: 3 }}>


      <Box sx={{ width: '100%', marginBottom: 2 }}>
        <Typography variant="h6">Attività didattica associata al laboratorio. Verranno importate le prenotazioni spazi da EasyRoom</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Ricerca docente per E-mail
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={emailOptions}
              inputValue={searchLib}
              defaultValue={props.apiAuthResponse}
              onInputChange={(event, newInputValue) => {
                searchLibHandler({ target: { value: newInputValue } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  type="text"
                  variant="outlined"
                  label="E-Mail"
                />
              )}
            />

          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">Selezionare l'attività didattica da associare al laboratorio</Alert>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{
        marginTop: 2,
      }} className="table-responsive">
        <MaterialReactTable
          columns={columnsLib}
          table={table}
        />
      </Box>


    </Card>


  );





}

export default SelectAd;
