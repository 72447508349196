import React from 'react';
import { Button, Container, Card, CardMedia, Typography, Box, Grid, FormControl } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function SelectBaseOs(props) {

    const back = () => {
        //e.preventDefault();
        props.prevStep();
    }

    const onClickOsLinux = () => {
        props.handleSelectOs('linux')
    }

    const onClickOsWindows = () => {
        props.handleSelectOs('windows')
    }




    return (
        <Container>
            <form>
                <Box mb={3}>
                    <Typography variant="h6">Scelta Sistema Operativo</Typography>
                </Box>


                <Card sx={{ padding: 3 }}>
                    <Grid container spacing={4} >

                        <Grid item xs={12} md={4} >
                            <Card sx={{
                                padding: 2,
                                height: 'auto', minHeight: '300px',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'space-between'
                            }}>
                                <FormControl fullWidth>
                                    <CardMedia
                                        component="img"
                                        image="/reqLabOsWin.png"
                                        alt="Linux"
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: 15,
                                            width: '25px',
                                        }}
                                    />
                                    <Typography variant="h4">Windows</Typography>
                                    <Typography variant="subtitle1">Selezionando questa opzione il laboratorio si baserà su sistema operativo Windows.</Typography>
                                </FormControl>

                            </Card>
                            <Button variant="contained" onClick={onClickOsWindows} style={{ marginTop: '16px' }}>
                                Seleziona
                            </Button>
                        </Grid>


                        <Grid item xs={12} md={4} >
                            <Card sx={{
                                padding: 2,
                                height: 'auto', minHeight: '300px',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'space-between'
                            }}>
                                <FormControl fullWidth>
                                    <CardMedia
                                        component="img"
                                        image="/reqLabOsTux.png"
                                        alt="Linux"
                                        style={{
                                            objectFit: 'cover',
                                            borderRadius: 15,
                                            width: '25px',
                                        }}
                                    />
                                    <Typography variant="h4">Linux</Typography>
                                    <Typography variant="subtitle1">Selezionando questa opzione il laboratorio si baserà su sistema operativo Linux.</Typography>
                                </FormControl>

                            </Card>
                            <Button variant="contained" onClick={onClickOsLinux} style={{ marginTop: '16px' }}>
                                Seleziona
                            </Button>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <Box mb={2} p={1} className="shadow-lg bg-white rounded">
                                <Typography variant="body1">1. Sistema operativo</Typography>
                                <Typography variant="body2"><b>{props.statusForm.os}</b></Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">2. Tipo immagine</Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">3. Selezione immagine</Typography>
                            </Box>
                            <Button
                                variant="contained"
                                startIcon={<ArrowBackIcon />}
                                onClick={back}
                            >
                                Indietro
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        </Container>
    );
}

export default SelectBaseOs;