import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Card } from '@mui/material';

function SelectExpDate(props) {


    const handleSelectChange = (event) => {
        const newExpDate = event.target.value;
        if (props.onExpDateChange) {
            props.onExpDateChange(newExpDate);
        }
    };

    return (
        <Card sx={{ padding: 3 }}>
            <Box>
                <div>
                    <InputLabel>Durata del laboratorio virtuale</InputLabel>
                </div>

                <FormControl fullWidth size="large">
                    <Select
                        labelId="exp-date-label"
                        value={props.expDate}
                        onChange={handleSelectChange}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Data di scadenza
                        </MenuItem>
                        <MenuItem value="18">18 mesi</MenuItem>
                        <MenuItem value="12">12 mesi</MenuItem>
                        <MenuItem value="6">6 mesi</MenuItem>
                        <MenuItem value="3">3 mesi</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Card>
    );
}

export default SelectExpDate;