import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Typography, InputLabel, Card, CardContent, CardHeader } from '@mui/material';
import ReactMarkdown from 'react-markdown';

function SelectRepoPkg(props) {
  const [selOptDisplay, setSelOptDisplay] = useState([]);
  const [selBundleOpt, setSelBundleOpt] = useState([]);
  const [dataPkg, setDataPkg] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  const { installedPackages, baseImage, setRepoPkgSel, setRepoPkgBundleSel } = props;


  const colPkg = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Nome pacchetto',
        size: 120,
      },
      {
        accessorKey: 'version',
        header: 'Versione pacchetto',
        size: 90,
      }
    ],
    [],
  );

  const table =
    useMaterialReactTable({

      columns: colPkg,
      data: dataPkg || [],

      defaultDisplayColumn: { enableResizing: false },
      //enableBottomToolbar: false,
      enableColumnResizing: false,
      //enableColumnVirtualization: true,
      //enableGlobalFilterModes: true,
      //enablePagination: false,
      // enableColumnPinning: true,
      //enableRowNumbers: false,
      //enableRowVirtualization: true,
      //muiTableContainerProps: { sx: { maxHeight: '600px' } },
      paginateExpandedRows: false,
      paginationDisplayMode: 'pages',
      positionToolbarAlertBanner: 'bottom',
      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
      initialState: {
        expanded: false,
        pagination: { pageIndex: 0, pageSize: 10 },
      },


      enableExpandAll: false, //disable expand all button
      enableSelectAll: false, //disable select all button
      state: { rowSelection },

      muiExpandButtonProps: ({ row, table }) => ({
        onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
        sx: {
          transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
          transition: 'transform 0.2s',
        },
      }),


      renderDetailPanel: ({ row }) =>
        row.original.name ? (
          <Box
            sx={{
              display: 'auto',
              margin: 'auto',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
              alignItems: 'start',
              width: '100%',
            }}
          >

            <ReactMarkdown>
              {row.original.description}
            </ReactMarkdown>
          </Box>
        ) : null,

    });

  var ret = (<div></div>);


  useEffect(() => {
    const selectedPackages = Object.keys(rowSelection).map(index => {
      const name = dataPkg[index].name;
      return { label: name, value: name };
    });

    // questo serve solo per la visualizzazione
    setSelOptDisplay((selBundleOpt.concat(selectedPackages)));

    // questo serve per i pacchetti selezionati
    setRepoPkgSel(selectedPackages);

    // questo serve per i pacchetti bundle
    setRepoPkgBundleSel(selBundleOpt);

  }, [rowSelection, dataPkg, selBundleOpt, setRepoPkgSel, setRepoPkgBundleSel]);


  useEffect(() => {
    const fetchAllData = (feed) => {
      const tableArray = [];

      fetch(process.env.REACT_APP_URL_REPO +
        '?feedName=' + feed +
        '&packageNameQuery=', {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tableArray.push({
              name: `${element.name}`,
              version: `${element.version}`,
              description: `${element.packageDescription}`,
            });
          });
          setDataPkg(tableArray);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    }


    const fetchDefaultData = (feedBundle) => {
      const tempArray = [];

      fetch(process.env.REACT_APP_URL_REPO +
        '?feedName=' + feedBundle +
        '&packageNameQuery=', {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempArray.push({
              label: `${element.name}`,
              value: `${element.name}`
            });
          });
          setSelOptDisplay(tempArray.concat(installedPackages));
          setSelBundleOpt(tempArray);
          //setSelBundleOpt(tempArray.concat(props.installedPackages));
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    }

    fetchAllData(baseImage.feed);
    fetchDefaultData(baseImage.feedBundle);

  }, [installedPackages, baseImage.feed, baseImage.feedBundle]);



  const packageSearch = () => {
    var ret;

    if (dataPkg.length > 0) {
      ret = (
        <MaterialReactTable table={table} />
      )
    } else {
      ret = (
        <Typography>Nessun pacchetto opzionale disponibile per l'immagine selezionata</Typography>
      )
    }

    return ret;
  }


  if (selOptDisplay) {
    ret = (
      <Card sx={{ padding: 3 }}>
        <Box>
          <InputLabel>Selezione pacchetti</InputLabel><br />
          <Typography variant="body2" color="textSecondary">
            Dati estratti dal repository LIBaaS.
          </Typography>
          <Card>
            <CardContent>
              <CardHeader title="Riepilogo pacchetti selezionati" />
              <Select
                isMulti
                value={selOptDisplay}
                isDisabled
              />
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                value={selOptDisplay}
                required={true}
                readOnly
              />
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <CardHeader title="Selezione dei pacchetti" />
              {packageSearch()}
            </CardContent>
          </Card>
        </Box>
      </Card>
    );
  }


  return ret;


}

export default SelectRepoPkg;
