import React, { useState, useEffect, useRef } from 'react';
import { TextField, InputLabel, Typography, Alert, InputAdornment, Card } from '@mui/material';


function LabName(props) {
    const [localLabName, setLocalLabName] = useState();
    const [showDupAlert, setShowDupAlert] = useState(false);
    const targetDupAlert = useRef(null);
    const { labNameSelected, setIsLabnameChecked, adSel, isSubmitted, onCompletion } = props;

    var ret;

    const labNameHandler = (event) => {
        setLocalLabName(event.target.value);
    }

    useEffect(() => {
        var globalLabName;

        if (localLabName) {
            if (adSel) {
                globalLabName = adSel.anaAfCod + '-' +
                    adSel.anaModCod + '-' +
                    localLabName;

                labNameSelected(globalLabName);
            } else {
                if (localLabName.length > 0) {
                    globalLabName = 'other-' + localLabName;
                    labNameSelected(globalLabName);
                }
            }

            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_CHECKNAME_LAB + "?labName="
                    + globalLabName, {
                    credentials: 'include',
                    method: "GET"
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        if (data.length > 0 && !isSubmitted) {
                            setShowDupAlert(true);
                            setIsLabnameChecked(false);
                        } else {
                            setShowDupAlert(false);
                            setIsLabnameChecked(true);
                            onCompletion();
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });

        }

    }, [localLabName, labNameSelected, setIsLabnameChecked, adSel, isSubmitted, onCompletion])

    ret = (
        <Card sx={{ padding: 3 }}>
            <InputLabel>Nome del Laboratorio Virtuale</InputLabel>
            <Typography variant="body2" color="textSecondary">
                <b>Dato obbligatorio</b>
            </Typography>
            <TextField
                fullWidth
                required
                inputRef={targetDupAlert}
                type="text"
                onChange={labNameHandler}
                placeholder="Inserire il nome del Laboratorio"
                variant="outlined"
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {adSel ? `${adSel.anaAfCod}-${adSel.anaModCod}-` : 'other-'}
                        </InputAdornment>
                    ),
                }}
            />


            {showDupAlert &&
                <Alert variant="danger">
                    Nome Laboratorio Virtuale già usato! Inserire un nome diverso.
                </Alert>
            }
            {!props.isLabnameSpellChecked && !showDupAlert &&
                <Alert variant="danger">
                    Attenzione! Il nome inserito contiene caratteri non consentiti. Inserire un nome diverso.
                </Alert>
            }
        </Card >


    );

    return ret;


}

export default LabName;